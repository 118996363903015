import { React, useEffect,useState, useRef, Suspense, lazy } from 'react';
import { Link,useLocation } from "react-router-dom";
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// import { axiosRequest } from '../util/axiosRequest';

const Login = lazy(() => import("../components/popups/Login"));

const S3url = process.env.REACT_APP_S3IMAGEBASE_URL;
const crmUrl = process.env.REACT_APP_CRM_URL;

let other_data = localStorage.getItem("other_data");

const Header = () => {
    const { pathname } = useLocation();
    const tabName = pathname.substring(1);
    const dispatch = useDispatch();
    const { is_logged,login_name,login_type } = useSelector(state => state.auth);
    const resizeHandlerRef = useRef();
    const [showLogin, setShowHideLogin] = useState(false);
    const [showHeaderDetsils, setShowHideHeaderDetails] = useState(false);
    const [isToggledHeader, setIsToggledHeader] = useState(window.innerWidth > 991);
    const [isMobileHeader, setIsMobileHeader] = useState(window.innerWidth <= 991);
    const [isToggleLoginUsrHeader, setisToggleLoginUsrHeader] = useState(false);

    const handelLoginPop = (show) => {
        setShowHideLogin(show);
    }
    const handelHeaderDetails = (show) => {
        setShowHideHeaderDetails(show);
    }

    const handleHeaderToggle = () => {
        setIsToggledHeader(!isToggledHeader);
        if (!isToggledHeader) {
          document.body.classList.add('overflow_hiddenn');
        } else {
          document.body.classList.remove('overflow_hiddenn');
        }
    };
    const handleLoginUsrToggle = () => {
        setisToggleLoginUsrHeader(!isToggleLoginUsrHeader);
    };

   // Debounced resize handler
    const handleResizeHeader = debounce(() => {
        setIsMobileHeader(window.innerWidth <= 991);
        setIsToggledHeader(window.innerWidth > 991);
    }, 200);

    resizeHandlerRef.current = handleResizeHeader;

  useEffect(() => {
    const resizeHandler = () => resizeHandlerRef.current();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);


  useEffect(() =>{
    window.scrollTo(0, 0);
    handelHeaderDetails(false);
    setIsToggledHeader(window.innerWidth > 991);
    document.body.classList.remove('overflow_hiddenn');
}, [pathname]);

    
    return (
        <>
            <header className="main_header" role="banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12">
                            <div className="header_static">
                                <div className="logo_section">
                                    <Link to={"/"}>
                                        <img src={`${S3url}logo.png`} alt="LET’S SECURE - Logo" loading="lazy" />
                                    </Link>
                                </div>
                                <div className="main_menu_inner">
                                    <label id="menu_open_btn" className={`menu_open_btn ${isToggledHeader ? 'menu_open_btn close' : 'menu_open_btn'}`} onClick={handleHeaderToggle}>
                                        <span className="main-menu-btn-icon"></span>
                                    </label>
                                    <div className="main_menu">
                                        {isToggledHeader && (
                                            <nav id="man_menu_show_hide">
                                                <ul>
                                                    <li>
                                                        <Link to={"/"}>Home</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={"/"}>Products</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={"/about-us"}>About Us</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={"/"}>Why Us</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={"/contact-us"}>Contact Us</Link>
                                                    </li>

                                                    {/*
                                                    {(String(is_logged) !== "true" && ['sp','employee','agent','user','third_party'].includes(login_type) === false && tabName !== "employee") &&
                                                    <li>
                                                        <Link to={"/become-pos"} title="Become POSP" className="highlight">
                                                            Become POSP
                                                        </Link>
                                                    </li>} */}
                                                    {(String(is_logged) === "true" && ['third_party'].includes(login_type) === false) && <li className='login_dropdown'>
                                                        <a href={void(0)} onClick={handleLoginUsrToggle}><img src={`${S3url}profile.svg`} alt={login_name}/> {login_name} <small className="down_icon"></small></a>
                                                        {isToggledHeader && <ul className="sub_menu pages">
                                                            <li>
                                                                <a href={`${crmUrl}/dashboard`} target='_blank' title="Insurance Advisors in">My Account</a>
                                                            </li>
                                                            <li>
                                                                <a href={`${crmUrl}/Logoutweb?type=${login_type}`} title="Insurance Advisors in">Logout</a>
                                                            </li>
                                                        </ul>}
                                                    </li>}
                                                </ul>
                                            </nav>
                                        )}
                                        {(String(is_logged) !== "true" && ['third_party'].includes(login_type) === false) && <div className="login_box">
                                            <button type="button" className="login_popup_cs"  onClick={() => handelLoginPop(true) }>Login </button>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Suspense fallback={<div></div>}>
                {showLogin && <Login handelLoginPop={handelLoginPop} />}
                </Suspense>
            </header>
        </>
    );
};

export default Header;
