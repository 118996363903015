import React, { Suspense,useEffect,useState } from 'react';
import { authSuccess } from './store/auth/action';
import { useDispatch } from 'react-redux';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { allRoute } from './util/routes';
import { ToastContainer } from "react-toastify";
import { axiosRequest } from './util/axiosRequest';
import Cookies from 'js-cookie';
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';  // Import jQuery
const crmUrl = process.env.REACT_APP_CRM_URL;


function App() {

  const location = useLocation();
  const dispatch = useDispatch();
  const [isUserLogout, setUserLogout] = useState(false);
  const [loginTypeUser, setLoginTypeUser] = useState(null);

  const checkAuthentication = () => {
    const is_logged = Cookies.get('is_logged');
    const login_type = Cookies.get('login_type');
    setLoginTypeUser(login_type);
    if (String(is_logged) === "true") {
      const login_name = Cookies.get('login_name');
      const login_data = Cookies.get('login_data');
      localStorage.setItem('is_logged', is_logged);
      localStorage.setItem('login_name', login_name);
      localStorage.setItem('login_data', login_data);
      localStorage.setItem('login_type', login_type);
      if(!login_data){
        setUserLogout(true);
      }
      dispatch(authSuccess({ is_logged, login_name, login_data, login_type }));
    } else if (String(is_logged) === "false" && login_type === "third_party") {
      
      const partner_name = Cookies.get('partner_name');
      const partner_code = Cookies.get('partner_code');
      const other_data = Cookies.get('other_data');
      const emitra_ids = Cookies.get('ids');

      if(partner_name){
        localStorage.setItem('partner_name', partner_name);
      }

      if(partner_code){
        localStorage.setItem('partner_code', partner_code);
      }

      if(other_data){
        localStorage.setItem('other_data', other_data);
      }

      if(emitra_ids){
        localStorage.setItem('ids', emitra_ids);
      }

      localStorage.removeItem("login_name");
      localStorage.removeItem("login_data");

      localStorage.setItem('is_logged', is_logged);
      localStorage.setItem('login_type', login_type);
      dispatch(authSuccess({ is_logged, login_type }));

    }else if (String(is_logged) === "false") {

      if(login_type !== "third_party"){
        localStorage.removeItem("partner_name");
        localStorage.removeItem("ids");
        localStorage.removeItem("partner_code");
        localStorage.removeItem("other_data");
      }

      localStorage.removeItem("login_name");
      localStorage.removeItem("login_data");
      localStorage.setItem('is_logged', false);
      if(login_type){
        localStorage.setItem('login_type', login_type);
        dispatch(authSuccess({ is_logged, login_type }));
      }else{
        localStorage.removeItem("login_type");
        dispatch(authSuccess({ is_logged }));
      }
    }else {
      if(typeof is_logged === "undefined" && typeof login_type === "undefined"){
        localStorage.removeItem("partner_name");
        localStorage.removeItem("ids");
        localStorage.removeItem("partner_code");
        localStorage.removeItem("other_data");
        localStorage.removeItem("login_name");
        localStorage.removeItem("login_data");
        localStorage.setItem('is_logged', false);

        if(login_type){
          localStorage.setItem('login_type', login_type);
          dispatch(authSuccess({ is_logged, login_type }));
        }else{
          localStorage.removeItem("login_type");
          dispatch(authSuccess({ is_logged }));
        }
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.jQuery = $;  // Set jQuery globally if window is defined
    }

    /* const currentVersion = localStorage.getItem('appVersion');
    if (currentVersion !== APP_VERSION) {
      localStorage.clear();
      caches.keys().then(names => {
        for (let name of names) caches.delete(name);
      });
      localStorage.setItem('appVersion', APP_VERSION);
      window.location.reload(true);
    } */
   
  }, []);

  useEffect(() => {

    checkAuthentication();

    /* axiosRequest("POST", "page-tag", {
      slug: location.pathname,
    }).then((data) => {
      if(data.status){
        if(data.data){
           let metaData = JSON.parse(atob(data.data));

           if (metaData.title) {
              document.title = metaData.title;
            }
            if (metaData.meta_description) {
              document.querySelector('meta[name="description"]').setAttribute('content', metaData.meta_description);
            }
            if (metaData.keywords) {
              document.querySelector('meta[name="keywords"]').setAttribute('content', metaData.keywords);
            }
            if (metaData.robots) {
              document.querySelector('meta[name="robots"]').setAttribute('content', metaData.robots);
            }
            if (metaData.og_type) {
              document.querySelector('meta[property="og:type"]').setAttribute('content', metaData.og_type);
            }
            if (metaData.og_locale) {
              document.querySelector('meta[property="og:locale"]').setAttribute('content', metaData.og_locale);
            }
            if (metaData.og_site_name) {
              document.querySelector('meta[property="og:site_name"]').setAttribute('content', metaData.og_site_name);
            }
            if (metaData.og_url) {
              document.querySelector('meta[property="og:url"]').setAttribute('content', metaData.og_url);
            }
            if (metaData.og_title) {
              document.querySelector('meta[property="og:title"]').setAttribute('content', metaData.og_title);
            }
            if (metaData.og_description) {
              document.querySelector('meta[property="og:description"]').setAttribute('content', metaData.og_description);
            }
            if (metaData.og_image) {
              document.querySelector('meta[property="og:image"]').setAttribute('content', metaData.og_image);
            }
            if (metaData.twitter_card) {
              document.querySelector('meta[name="twitter:card"]').setAttribute('content', metaData.twitter_card);
            }
            if (metaData.twitter_creator) {
              document.querySelector('meta[name="twitter:creator"]').setAttribute('content', metaData.twitter_creator);
            }
            if (metaData.twitter_url) {
              document.querySelector('meta[property="twitter:url"]').setAttribute('content', metaData.twitter_url);
            }
            if (metaData.twitter_image) {
              document.querySelector('meta[property="twitter:image"]').setAttribute('content', metaData.twitter_image);
            }
            if (metaData.twitter_title) {
              document.querySelector('meta[property="twitter:title"]').setAttribute('content', metaData.twitter_title);
            }
            if (metaData.twitter_description) {
              document.querySelector('meta[property="twitter:description"]').setAttribute('content', metaData.twitter_description);
            }
            if (metaData.canonical_link) {
              document.querySelector('link[rel="canonical"]').setAttribute('href', metaData.canonical_link);
            }
        }
      } 
    })
    .catch((error) => {
        console.log(error);
    }); */
  }, [location.pathname]);

  return (
    <Suspense fallback={<div></div>}>
    {isUserLogout ? <div className="main_custom_popup" id="login_popup_main_reset">
              <div className="main_custom_popup_inner">              
                  <div className="main_custom_popup_inner_body">
                      <div className="login_popup_data">
                        <p>It seems your login session is mismatched please refresh control + shift + r , if issue is still then click the <br/>
                        <a className="quote_url" href={`${crmUrl}/Logoutweb?type=${loginTypeUser}`} title="Logout">Logout</a><br/>
                         and Login again. </p>
                      </div>
                  </div>
              </div>
          </div> : 
      <Routes>
      {allRoute.map((routeData, index) => ( 
        <Route
          key={index}
          exact={routeData.exact}
          path={routeData.path}
          element={
          <routeData.layout>
            <routeData.component type={routeData?.type} tp_only={routeData?.tp_only} />
          </routeData.layout>
          }
        />
      ))}
    </Routes>}
    <ToastContainer position="top-right" autoClose={3000} />
  </Suspense>
  );
}

export default App;
