import React from 'react'


const QuoteLayout = ({children}) => {
  return (
    <>
      {/* <Toastr/> */}
      {children}
    </>
  )
}

export default QuoteLayout