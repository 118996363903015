import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SIDEBAR_TOGGLE,
  LOADING,
  UPDATE_TOKEN,
} from "./actionTypes";

export const authSuccess = (updates) => {
  if (updates.is_logged) {
    const { loggedIn, login_type, login_data } = updates;
    /* localStorage.setItem("login_data", login_data);
    localStorage.setItem("login_type", login_type);
    localStorage.setItem("loggedIn", loggedIn); */

    if(login_type !== "third_party"){
      localStorage.removeItem("partner_name");
      localStorage.removeItem("ids");
      localStorage.removeItem("partner_code");
      localStorage.removeItem("other_data");
    }
  }
  return {
    type: AUTH_SUCCESS,
    updates,
  };
};

export const sidebarToggle = (updates) => {
  return {
    type: SIDEBAR_TOGGLE,
    updates,
  };
};

export const logout = () => {

  localStorage.setItem("is_logged", false);
  localStorage.removeItem("login_name");
  localStorage.removeItem("login_data");
  localStorage.removeItem("login_type");
  
  return {
    type: AUTH_LOGOUT
  };
};

export const updateLoading = (updates) => {
  return {
    type: LOADING,
    updates,
  };
};

export const updateToken = (updates) => {
  localStorage.setItem("token", updates.token);
  return {
    type: UPDATE_TOKEN,
    updates,
  };
};
